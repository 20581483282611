<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >开课记录</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">学习时间设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox" style="display: flex">
            <div>
              <span>课程名称:{{ courseName }}</span>
            </div>
          </div>
          <div class="df" style="padding-right: 20px">
            <el-button type="primary" class="bgc-bv" round @click="setTimeBatch"
              >设置学习时间</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="clearTimeBatch"
              >清空学习时间</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              row-key="courseOutlineId"
              @selection-change="checkListData"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                type="selection"
                width="50px"
                align="center"
                fixed
                :reserve-selection="true"
                :selectable="selectable"
              ></el-table-column>
              <el-table-column
                label="课件名称"
                align="left"
                show-overflow-tooltip
                prop="kpointName"
                min-width="150"
              />
              <el-table-column
                label="课件时长"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.kpointDurationFormat || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="学习日期"
                align="center"
                show-overflow-tooltip
                prop="studyDate"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.studyStartDate">{{ scope.row.studyStartDate | momentDate }}至{{ scope.row.studyEndDate | momentDate}}</span>
                  <span v-else>--</span>
                  
                </template>
              </el-table-column>
              <el-table-column
                label="学习时间"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{
                    scope.row.studyStartTime
                      ? scope.row.studyStartTime.slice(0, 5)
                      : ""
                  }}-{{
                    scope.row.studyEndTime
                      ? scope.row.studyEndTime.slice(0, 5)
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="设置人"
                align="center"
                show-overflow-tooltip
                prop="setPerson"
              />
              <el-table-column
                label="设置时间"
                align="center"
                show-overflow-tooltip
                prop="setTime"
              />
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="addModel(scope.row)"
                    >设置</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="deleteStudyTime(scope.row.courseOutlineId)"
                    >清空</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="设置学习时间"
      :visible.sync="dialogFormVisible"
      width="35%"
      @close="closeModel"
    >
      <el-form
        ref="groupForm"
        :model="groupForm"
        style="width: 100%"
        :rules="rules"
      >
        <el-form-item label="学习日期：" label-width="100px" prop="studyDate">
          <!-- <el-date-picker
            v-model="groupForm.studyDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            size="small"
            placeholder="选择日期"
          > -->
          <!-- </el-date-picker> -->
          <el-date-picker
            v-model="groupForm.studyDate"
            type="daterange"
            range-separator="至"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间：" label-width="100px" prop="studyTime">
          <el-time-picker
            size="small"
            is-range
            arrow-control
            v-model="groupForm.studyTime"
            range-separator="至"
            format="HH:mm"
            value-format="HH:mm:ss"
            :editable="false"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/SubsidyStandardSetting",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: this.$route.query.courseName,
      projectId: this.$route.query.projectId,
      projectCourseId: this.$route.query.projectCourseId,
      courseOutlineIds: [],
      multipleTable: [],
      groupForm: {
        studyDate: "",
        studyTime: ["00:00:00", "23:59:00"],
      },
      dialogFormVisible: false, //弹框
      rules: {
        studyDate: [
          { required: true, trigger: "change", message: "学习日期不能为空" },
        ],
        studyTime: [
          { required: true, trigger: "blur", message: "学习时间不能为空" },
        ],
      },
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        projectCourseId: this.projectCourseId,
      };
      this.doFetch({
        url: "/biz/projectCourseOutline/studyTimeSetList",
        params,
        pageNum,
      });
    },
    //弹框打开
    addModel(row) {
      this.courseOutlineIds = [];
      this.courseOutlineIds.push(row.courseOutlineId);
      if (row.studyStartDate) {
        this.groupForm.studyDate = [row.studyStartDate.replaceAll("/", "-"), row.studyEndDate.replaceAll("/", "-")];
        this.groupForm.studyTime = [row.studyStartTime, row.studyEndTime];
      } else {
        this.groupForm.studyDate = "";
        this.groupForm.studyTime = ["00:00:00", "23:59:00"];
        console.log(this.groupForm);
      }
      this.dialogFormVisible = true;
    },
    //弹框关闭
    closeModel() {
      this.$refs["groupForm"].resetFields();
      (this.groupForm = {
        studyDate: "",
        studyTime: ["00:00:00", "23:59:59"],
      }),
        (this.dialogFormVisible = false);
    },
    //弹框确定
    doOk() {
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          let params = {
            projectId: this.projectId,
            courseOutlineIds: this.courseOutlineIds,
          };
          if (this.groupForm.studyTime) {
            (params.studyStartTime =
              this.groupForm.studyTime[0].slice(0, 5) + ":00"),
              (params.studyEndTime =
                this.groupForm.studyTime[1].slice(0, 5) + ":00");
          }
          if (this.groupForm.studyDate) {
params.studyStartDate = this.groupForm.studyDate[0]
params.studyEndDate = this.groupForm.studyDate[1]
          }
          this.$post(
            "/biz/projectCourseOutline/setStudyTimeBatch",
            params
          ).then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });

              this.closeModel();
              this.getData(-1);
              this.$refs.multipleTable.clearSelection();
            }
          });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    // 勾选列表数据
    checkListData(rowArr) {
      this.multipleTable = rowArr;
    },
    //批量设置学习时间
    setTimeBatch() {
      if (this.multipleTable.length > 0) {
        this.courseOutlineIds = [];
        this.courseOutlineIds = this.multipleTable.map((t) => {
          return t.courseOutlineId;
        });
        this.dialogFormVisible = true;
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    //清空学习时间
    deleteStudyTime(courseOutlineId) {
      this.doDel({
        url: "/biz/projectCourseOutline/emptyStudyTime",
        msg: "是否确认清除学习时间限制？",
        ps: {
          type: "post",
          data: { courseOutlineIds:[courseOutlineId] },
        },
      });
    },
    clearTimeBatch(){
      if (this.multipleTable.length > 0) {
        this.courseOutlineIds = [];
        this.courseOutlineIds = this.multipleTable.map((t) => {
          return t.courseOutlineId;
        });
        this.$confirm('清除后数据将不可恢复，确认要清除所有的时间设置吗？', '删除', {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          confirmButtonClass:'bgc-bv',
          type: "warning",
        })
          .then(() => {
            this.$post('/biz/projectCourseOutline/emptyStudyTime', { courseOutlineIds:this.courseOutlineIds })
              .then(() => {
                setTimeout(() => {
                  this.$message({
                    message: "操作成功",
                    type: "success",
                    duration: 1000,
                  });
                  this.getData(-1);
                  this.$refs.multipleTable.clearSelection();
                }, 300);
              })
              .catch(() => {
                return;
              });
          })
          .catch(() => {
            return;
          });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.name != "evaluate/SubsidyStandardSetting") return;
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.params = {};
            this.getData();
          } else {
            this.getData(-1);
          }
        } else if (sessionStorage.getItem("refresh") != 0) {
          sessionStorage.setItem("refresh", 0);

          this.params = {};
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
</style>
<style lang="less">
.no_border_upload {
  .el-upload.el-upload--text {
    border: 0;
    height: auto !important;
    margin: 0 8px;
  }
}
</style>
